module.exports = [{
      plugin: require('../node_modules/@prismicio/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"globalinheritance","path":"/preview","previews":true,"pages":[{"type":"News","match":"/news/:uid","path":"/blog-preview","component":"/codebuild/output/src1718186850/src/gi-web-prismic/src/templates/post.tsx"},{"type":"Program","match":"/programs/:uid","path":"/blog-preview","component":"/codebuild/output/src1718186850/src/gi-web-prismic/src/templates/program.tsx"},{"type":"Sign Up","match":"/sign-up/:uid","path":"/sign-up","component":"/codebuild/output/src1718186850/src/gi-web-prismic/src/templates/sign-up.tsx"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/favicon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"G-5852SXZLK1","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"PAGE_NAVIGATION","enableWebVitalsTracking":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
